@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --primary-black : #303030;
  --secondary-black:#242424;
  --primary-white : #fbfbfb;
  --primary-gray:#5d5d5d;
  --secondary-gray:#808080;
  // --primary-gray: #473c30;
  // --secondary-white:#999999;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: "Playfair Display", serif;
   font-size: 62.5%;
}

body {
  background-color: white;
  line-height: 1.5;
}

// Default setting for number input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.lora-family{
  font-family: "Lora", serif;
}

.open-sans-family{
  font-family: "Open Sans", sans-serif; 
 
}

.txt-black{
  color: var(--primary-black);
}

.txt-black-secondary{
  color: var(--secondary-black);
}

.txt-gray-primary{
  color:  var(--primary-gray)
}

.txt-gray-secondary{
 color:var(--secondary-gray)
}

.max-width{
  max-width: 1380px;
  margin : 0 auto;
  @media (width<=1240px) {
      padding: 0 2rem;
  }
}
 
.layout-section{
  padding: 4rem 0;
  @media (width<768px) {
     padding: 2.4rem 0;
  }
}

.pageBanner{
  width: 100%;
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  @media(width<1024px) {
      height: 400px;
  }
  @media(width<600px) {
    height: 280px;
}
  &::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(5, 4, 4, 0.4);
    z-index: -1;
  }
}

.pageBanner-h1{
  font-size: 5.5rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--primary-white); 

  @media (width<768px) {
     font-size: 2.5rem;
  }

}

.heading-h2{
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--primary-gray);
  font-weight: 500;
}

.heading-h3{
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 700;
  @media (width<768px) {
    font-size: 2rem;
    line-height: 3.4rem;
  }
}

.para{
 font-size: 1.6rem;
 font-weight: 400;
 line-height: 2.4rem;
 color: var(--secondary-white);
 @media (width<=600px) {
  font-size: 1.4rem;
  text-align: justify;
 }
}

.common-btn{
  background-color: #C79678;
  color: white;
  border-radius: 8px;
  padding: 1.6rem 4rem;
  font-size: 1.6rem;
  text-transform: capitalize;
  font-weight: 400;
  &:active{
    scale: 0.9;
  }
  @media (width<600px) {
    font-size: 1.3rem;
  }
  
}

// Swiper bulltes customization*****
.swiper-button-next{
  color: white !important;
  right: 5% !important;
  &::after{
    font-size: 3rem !important;
  }
}
.swiper-button-prev{
  color: white !important;
  left: 5% !important;
  &::after{
    font-size: 3rem !important;
  }
}
.swiper-pagination-bullets{
  padding: 1rem 0;
  .swiper-pagination-bullet{
     width: 35px;
     height: 3.2px;
     border-radius: 0;
     background-color: white;
     @media (width<=600px) {
       width: 20px;
     }
  }
  .swiper-pagination-bullet-active{
    width: 35px;
    height: 3.2px;
    border-radius: 0;
    background-color: white;
    @media (width<=600px) {
      width: 20px;
    }
 }
}



